import { DateTime } from 'luxon';

export const minutesAgo = (datetime: string) => {
  const now = DateTime.now();
  const then = DateTime.fromISO(datetime);
  const minutesAgo = Math.round(now.diff(then).as('minutes'));
  return minutesAgo;
};

export const secondsAgo = (datetime: string) => {
  const now = DateTime.now();
  const then = DateTime.fromISO(datetime);
  const secondsAgo = Math.round(now.diff(then).as('seconds'));
  return secondsAgo;
};

export const displayDatetime = (datetime: string) => {
  const then = DateTime.fromISO(datetime);
  return then.toLocaleString(DateTime.DATETIME_SHORT);
};

export const closestTime = (time: string, times: string[]) => {
  const datetime = DateTime.fromISO(time);
  const datetimes = times.map((t) => DateTime.fromISO(t));
  let closest = datetimes[0];
  datetimes.slice(1).forEach((t) => {
    if (
      Math.abs(datetime.diff(t).as('seconds')) <
      Math.abs(datetime.diff(closest).as('seconds'))
    ) {
      closest = t;
    }
  });
  return closest;
};
