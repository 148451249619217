import { DateTime } from 'luxon';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type FetchMode = 'single' | 'foveated' | 'all' | 'static';
export type ImageUrls = [string, string, string, string];

type State = {
  bearing: number;
  setBearing: (b: number) => void;

  altitude: number;
  setAltitude: (a: number) => void;

  zoom: number;
  setZoom: (z: number) => void;

  fetchMode: FetchMode;
  setFetchMode: (a: FetchMode) => void;

  // If images is set, the viewer will go to a static viewing mode for the URLs
  // provided. Otherwise, the viewer will stream live images.
  images?: ImageUrls;
  setImages: (u?: ImageUrls) => void;

  lastImageReceived?: DateTime;
  setLastImageReceieved: (dt: DateTime) => void;
};

export const useStore = create<State>()(
  subscribeWithSelector((set) => ({
    bearing: 0,
    setBearing: (bearing) => set({ bearing }),

    altitude: 0,
    setAltitude: (altitude) => set({ altitude }),

    zoom: 35,
    setZoom: (zoom) => set({ zoom }),

    fetchMode: 'foveated',
    setFetchMode: (fetchMode) => set({ fetchMode }),

    images: undefined,
    setImages: (images) => set({ images }),

    lastImageReceived: undefined,
    setLastImageReceieved: (ms) => set({ lastImageReceived: ms }),
  }))
);
