import { DateTime } from 'luxon';
import { CSSProperties, useEffect, useState } from 'react';
import { useStore } from '../../state';
import { secondsAgo } from './_time-functions';

type Props = {
  style?: CSSProperties;
};

export default function LastImageReceived({ style }: Props) {
  const lastImageReceived = useStore((store) => store.lastImageReceived);
  const [, setNow] = useState<DateTime>();

  useEffect(() => {
    const timeInterval = setInterval(() => setNow(DateTime.now()), 1e3);
    return () => clearInterval(timeInterval);
  });

  if (!lastImageReceived) {
    return (
      <span style={style} className="last-image-recv">
        No images received yet.
      </span>
    );
  }

  return (
    <span style={style} className="last-image-recv">
      Last image received {secondsAgo(lastImageReceived.toISO() || '')} seconds
      ago.
    </span>
  );
}
