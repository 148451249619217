import { css } from '@emotion/css';

type Props = {
  azimuth: number;
};

export default function AzimuthIndicator({ azimuth }: Props) {
  return (
    <div className={style}>
      <span className="label">Azimuth</span>
      <span className="value">{azimuth.toFixed(1)}°</span>
    </div>
  );
}

const style = css({
  position: 'absolute',
  width: 100,
  height: 75,
  right: 'calc(100%/2 - 50px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px 15px',
  backgroundColor: '#ffffffcc',
  borderRadius: '0 0 5px 5px',
  '.value': {
    fontSize: '1.75em',
  },
  '@media (max-width: 800px)': {
    top: 0,
    right: 75,
    height: 60,
    width: 75,
    borderBottomRightRadius: 0,
    '.label': {
      fontSize: '.8em',
    },
    '.value': {
      fontSize: '1.25em',
    },
  },
});
