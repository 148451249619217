import E from '../images/merrill/cal.east.jpg';
import N from '../images/merrill/cal.north.jpg';
import S from '../images/merrill/cal.south.jpg';
import W from '../images/merrill/cal.west.jpg';
import { Config } from '../types';

const config: Config = {
  baseUrl: 'https://pano.montiscorp.com/img',
  title: 'Merrill Field MWOS Panorama - Montis Corp',
  defaultLookAngle: -115,
  // defaultLookAngle: 70,
  azimuthOffset: -90,
  defaultFov: 80,
  latitude: 61.214214,
  longitude: -149.842739,
  stationId: 1,
  calibrate: false,
  controls: {
    showStationInfo: true,
    showAzimuthIndicator: true,
    showElevationIndicator: true,
    showWarning: false,
    showPanSlider: false,
    showZoomSlider: false,
    allowTouch: true,
    allowScroll: true,
    showPanoPicker: true,
  },
  refresh: {
    mode: 'foveated',
    intervalMs: 2000,
  },
  cameras: {
    camera1: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/mwos1/0-full.jpg',
      calibrationImage: N,
      azimuthAngle: 0,
      uniforms: {
        rotation: { value: -0.01 },
        vshift: { value: -0.01 },
        hshift: { value: 0.0 },
        brighten: { value: 1.17 },
        blueAdjust: { value: 1.0 },
        redAdjust: { value: 1.0 },
      },
    },
    camera2: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/mwos1/90-full.jpg',
      calibrationImage: E,
      azimuthAngle: 90,
      uniforms: {
        rotation: { value: -0.04 },
        vshift: { value: -0.0 },
        hshift: { value: -0.0 },
        brighten: { value: 1.16 },
        blueAdjust: { value: 0.98 },
        redAdjust: { value: 1.0 },
      },
    },
    camera3: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/mwos1/180-full.jpg',
      calibrationImage: S,
      azimuthAngle: 180,
      uniforms: {
        rotation: { value: -0.04 },
        vshift: { value: 0.01 },
        hshift: { value: -0.01 },
        brighten: { value: 1.1 },
        blueAdjust: { value: 0.94 },
        redAdjust: { value: 1.0 },
      },
    },
    camera4: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/mwos1/270-full.jpg',
      calibrationImage: W,
      azimuthAngle: 270,
      uniforms: {
        rotation: { value: 0.01 },
        vshift: { value: 0.025 },
        hshift: { value: -0.04 },
        brighten: { value: 1.05 },
        blueAdjust: { value: 0.96 },
        redAdjust: { value: 1.0 },
      },
    },
  },
  labels: [
    {
      name: 'Ship Creek Hill',
      elevation: 1172,
      latitude: 61.2067,
      longitude: -149.561,
      visualShift: {
        bearing: 0,
        elevation: 50,
      },
    },
    {
      name: 'Wolverine Peak',
      elevation: 1369,
      latitude: 61.135,
      longitude: -149.61401,
      visualShift: {
        bearing: 0,
        elevation: -50,
      },
    },
    {
      name: 'Hope Point',
      elevation: 1102,
      latitude: 60.9369,
      longitude: -149.71599,
      visualShift: {
        bearing: -1.75,
        elevation: -325,
      },
    },
    {
      name: 'Flattop Mountain',
      elevation: 1070,
      latitude: 61.0897,
      longitude: -149.669,
    },
    {
      name: 'Rendezvous Peak',
      elevation: 1240,
      latitude: 61.25012,
      longitude: -149.50637,
      visualShift: {
        bearing: 0,
        elevation: -100,
      },
    },
    {
      name: 'Roundtop',
      elevation: 1059,
      latitude: 61.3431,
      longitude: -149.41599,
      visualShift: {
        bearing: -0.5,
        elevation: -50,
      },
    },
  ],
};

export default config;
