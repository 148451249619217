import { type CognitoUserPoolConfig } from '@aws-amplify/core';
import '@aws-amplify/ui-react/styles.css';
import { css } from '@emotion/css';
import { Amplify } from 'aws-amplify';
import { createRoot, Root } from 'react-dom/client';
import Pano from '../lib/index';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { StrictMode, useEffect, useState } from 'react';
import merrill from '../lib/config/merrill';
import rampart from '../lib/config/rampart';
import { Config } from '../lib/types';
import SignIn from './components/SignIn';

const style = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const userPoolConfig: CognitoUserPoolConfig = {
  userPoolClientId: '6af6ji7r1qndn9fp8hovhd142t',
  userPoolId: 'us-west-2_ZAnEMUXbw',
  loginWith: {
    oauth: {
      domain: 'montiscorp-prod.auth.us-west-2.amazoncognito.com',
      scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: ['exp+visroute://', 'visrouteapp://'],
      redirectSignOut: ['exp+visroute://', 'visrouteapp://'],
      responseType: 'code',
      // providers: ['Google'],
    },
    email: true,
  },
};

Amplify.configure({
  Auth: {
    Cognito: userPoolConfig,
  },
});

const PanoWrapper = () => {
  const [config, setConfig] = useState<Config>();
  const [needsAuth, setNeedsAuth] = useState<boolean>();

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  useEffect(() => {
    const href = window.location.href;
    const config =
      href.match(/rampart/g) || href.match(/demo/g) ? rampart : merrill;
    const shouldAuth = href.match(/auth/g);
    if (shouldAuth) {
      setNeedsAuth(true);
    }
    config.controls.showStationInfo = href.match(/info=false/g) === null;
    config.controls.showElevationIndicator = href.match(/elev=false/g) === null;
    config.controls.showAzimuthIndicator =
      href.match(/azimuth=false/g) === null;

    config.controls.showWarning = href.match(/warning=false/g) === null;
    config.controls.showZoomSlider = href.match(/zoomSlider=true/g) !== null;
    config.controls.showPanSlider = href.match(/panSlider=true/g) !== null;
    config.controls.allowScroll = href.match(/allowScroll=false/g) === null;
    config.controls.showPanoPicker = href.match(/picker=true/g) !== null;
    config.calibrate = href.match(/calibrate/g) !== null;

    document.title = config.title;
    setConfig(config);
  }, [authStatus]);

  if (!config) {
    return false;
  }

  if (authStatus === 'unauthenticated' && needsAuth) {
    return <SignIn />;
  }

  return (
    <div className={style}>
      <Pano config={config} />
      {authStatus === 'authenticated' && (
        <button
          style={{
            position: 'absolute',
            bottom: 5,
            right: 5,
            padding: '.25em',
            fontSize: 18,
            fontWeight: 500,
            cursor: 'pointer',
            borderRadius: 3,
          }}
          onClick={signOut}
        >
          Sign Out
        </button>
      )}
    </div>
  );
};

const App = () => {
  return (
    <Authenticator.Provider>
      <StrictMode>
        <PanoWrapper />
      </StrictMode>
    </Authenticator.Provider>
  );
};

let root: Root;

const main = () => {
  if (!root) {
    const container = document.getElementById('root');
    root = createRoot(container);
  }
  root.render(<App />);
};

main();
